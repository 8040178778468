<template>
  <div class="">
    <div
      class="card border-0 my-4 shadow-sm"
      v-if="permissions.includes('template.read')"
    >
      <div class="card-body">
        <!-- Title and button -->
        <div
          class="d-flex justify-content-between align-items-center px-2 pb-3"
        >
          <h4 class="text-primary m-0">Templates</h4>
          <button
            class="btn btn-primary btn-sm"
            @click="templateModalHandler('add')"
          >
            Add
          </button>
        </div>
        <!-- Filter table -->
        <div class="p-2">
          <vue-good-table
            mode="remote"
            @on-page-change="onPageChange"
            @on-sort-change="onSortChange"
            @on-column-filter="onColumnFilter"
            @on-per-page-change="onPerPageChange"
            :isLoading.sync="isLoading"
            :columns="template_columns"
            :rows="template_rows"
            :pagination-options="{ enabled: true }"
            styleClass="vgt-table no-border"
          >
            <template slot="table-row" scope="props">
              <div
                v-if="props.column.label == 'Action'"
                class="d-flex justify-content-center align-items-center p-2 gap-2"
              >
                <button
                  class="btn-view"
                  v-if="permissions.includes('template.read')"
                  @click="templateModalHandler('view', props.row)"
                >
                  <i class="fa fa-eye fs-5"></i>
                </button>
                <button
                  class="btn-pencil"
                  v-if="permissions.includes('template.write')"
                  @click="templateModalHandler('update', props.row)"
                >
                  <i class="fa fa-pencil fs-5"></i>
                </button>
                <button
                  class="btn-trash"
                  v-if="permissions.includes('template.delete')"
                  @click="deleteTaskTemplate(props.row.id)"
                >
                  <i class="fa fa-trash fs-5"></i>
                </button>
              </div>
            </template>
            <template slot="pagination-bottom" slot-scope="props">
              <CustomPagination
                class="pt-2"
                :pageTabs="4"
                :total="props.total"
                :pageChanged="props.pageChanged"
                :perPageChanged="props.perPageChanged"
                :serverParams="serverParams"
              ></CustomPagination>
            </template>
          </vue-good-table>
        </div>
      </div>
    </div>
    <div v-else="!permissions.includes('clients.read')">
      <UnauthorizedPage></UnauthorizedPage>
    </div>
    <!-- Template Add/Update Modal -->

    <div class="modal fade" id="templateModal" aria-hidden="true" tabindex="-1">
      <div class="modal-dialog modal-dialog-centered modal-xl">
        <div class="modal-content">
          <div class="modal-header">
            <h3 class="m-0" id="taskModalLongTitle" v-text="modal_title"></h3>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body" style="overflow-y: auto">
            <div
              class="d-flex justify-content-center align-items-center"
              v-if="!dataLoaded"
            >
              <div
                class="spinner-border text-primary p-3"
                style="height: 3rem; width: 3rem"
              ></div>
            </div>
            <form @submit.prevent="submitHandler" v-else>
              <!-- For template details -->
              <div class="mb-3">
                <label
                  :class="[readonly ? '' : 'mandatory', 'form-label']"
                  id="short_desc"
                  >Short Description</label
                >
                <input
                  class="form-control"
                  required
                  v-model="reqObj.short_desc"
                  for="short_desc"
                  placeholder="Enter the short desc"
                  :readonly="readonly"
                />
              </div>
              <div class="mb-3">
                <label
                  :class="[readonly ? '' : 'mandatory', 'form-label']"
                  id="description"
                  >Description</label
                >
                <textarea
                  class="form-control"
                  required
                  v-model="reqObj.description"
                  for="description"
                  rows="5"
                  placeholder="Enter the description"
                  :readonly="readonly"
                ></textarea>
              </div>
              <!-- For activities detail -->
              <div>
                <div
                  class="d-flex justify-content-between align-items-center mb-2"
                >
                  <h4 class="m-0">Activities</h4>
                  <button
                    class="btn-add mt-3"
                    type="button"
                    @click="activityActionHandler('add')"
                    v-if="!readonly"
                  >
                    <i class="fa fa-plus fs-5"></i>
                  </button>
                </div>
                <h5
                  class="text-light-emphasis text-center p-2"
                  v-if="reqObj.activities.length == 0"
                >
                  No Activities Added Yet
                </h5>
                <draggable
                  v-model="reqObj.activities"
                  @start="drag = true"
                  @end="drag = false"
                  v-bind="dragOptions"
                  :disabled="readonly"
                  class="list-group"
                >
                  <transition-group
                    type="transition"
                    :name="!drag ? 'flip-list' : null"
                  >
                    <div
                      class="d-flex border border-light-subtle rounded-3 mb-2"
                      :class="readonly ? '' : 'list-group-item'"
                      v-for="(item, index) in reqObj.activities"
                      :key="index"
                    >
                      <div
                        class="flex-grow-1 px-2 pt-2 pb-3 d-flex align-items-center gap-3 flex-md-nowrap flex-wrap"
                      >
                        <div class="align-self-start">
                          <label
                            :class="[readonly ? '' : 'mandatory', 'form-label']"
                            >Est. Hours</label
                          >
                          <flat-pickr
                            v-model="item.estimated_minutes"
                            :config="time_config"
                            class="form-control bg-white"
                            style="width: 100px"
                            :disabled="readonly"
                            required
                          ></flat-pickr>
                        </div>
                        <div class="flex-fill">
                          <label
                            :class="[readonly ? '' : 'mandatory', 'form-label']"
                            >Activity Description</label
                          >
                          <textarea
                            placeholder="Enter activity description"
                            v-model="item.activity_desc"
                            rows="1"
                            class="form-control"
                            required
                            :readonly="readonly"
                          ></textarea>
                        </div>
                      </div>
                      <div
                        class="border-start border-light-subtle bg-light-subtle px-1 rounded-end-3 d-flex flex-column justify-content-around align-items-center"
                        v-if="!readonly"
                      >
                        <!-- <button class="d-block border-0 bg-transparent align-self-start" type="button">
                                        <i class="fa fa-check text-light-emphasis"></i>
                                    </button>
                                    <button class="d-block border-0 bg-transparent align-self-start" type="button">
                                        <i class="fa fa-pencil text-light-emphasis"></i>
                                    </button> -->
                        <button
                          class="bg-transparent px-1 btn-trash"
                          type="button"
                          @click="activityActionHandler('delete', index)"
                        >
                          <i class="fa fa-trash text-danger"></i>
                        </button>
                      </div>
                    </div>
                  </transition-group>
                </draggable>
              </div>
              <div class="text-end mt-3" v-if="!readonly">
                <button class="btn btn-primary btn-sm me-2" type="submit">
                  Save
                </button>
                <button
                  class="btn btn-outline-secondary btn-sm"
                  type="button"
                  data-bs-dismiss="modal"
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import user from "@/ajax/user";
import CustomPagination from "../../components/CustomPagination.vue";
import moment from "moment";
import draggable from "vuedraggable";
import UnauthorizedPage from "@/views/Layouts/UnauthorizedPage.vue";

export default {
  components: {
    CustomPagination,
    draggable,
    UnauthorizedPage,
  },
  data() {
    return {
      siteLogReq: {
        site_name: "templates",
      },
      template_columns: [
        {
          label: "Short Desc",
          field: "short_desc",
          thClass: "custom-th",
          tdClass: "custom-td",
          filterable: true,
          filterOptions: {
            enabled: true,
            placeholder: "Search Short Desc",
          },
        },
        {
          label: "Description",
          field: "description",
          thClass: "custom-th",
          tdClass: "custom-td",
          filterable: true,
          filterOptions: {
            enabled: true,
            placeholder: "Search Short Desc",
          },
        },
        {
          label: "No. of Activities",
          field: "total_activities",
          thClass: "custom-th",
          tdClass: "custom-td",
          filterable: true,
          filterOptions: {
            enabled: true,
            placeholder: "Search Short Desc",
          },
        },
        {
          label: "Total Duration",
          field: "total_duration",
          thClass: "custom-th",
          tdClass: "custom-td",
          sortable: false,
        },
        {
          label: "Action",
          field: "action",
          sortable: false,
          thClass: "custom-th",
          tdClass: "custom-td",
        },
      ],
      template_rows: [],
      modal_title: null,
      reqObj: {
        short_desc: null,
        description: "",
        activities: [],
      },
      time_config: {
        allowInput: true,
        enableTime: true,
        noCalendar: true,
        time_24hr: true,
        dateFormat: "H:i",
        defaultDate: "00:00",
        disableMobile: true,
      },
      template_id: null,
      dataLoaded: true,
      serverParams: {
        filters: {},
        sort: [
          {
            field: "",
            type: "asc",
          },
        ],
        page: 1,
        perpage: 100,
      },
      isLoading: false,
      readonly: false,
      drag: false,
      permissions: localStorage.getItem("permissions"),
    };
  },
  computed: {
    dragOptions() {
      return {
        animation: 200,
        group: "description",
        disabled: false,
        ghostClass: "ghost",
      };
    },
  },
  methods: {
    updateParams(newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps);
    },
    onPageChange(params) {
      this.updateParams({ page: params.currentPage });
      this.getTemplates();
    },

    onPerPageChange(params) {
      this.updateParams({ perpage: params.currentPerPage });
      this.serverParams.page = 1;
      this.getTemplates();
    },

    onSortChange(params) {
      this.updateParams({
        sort: [
          {
            type: params[0].type,
            field: params[0].field,
          },
        ],
      });
      this.getTemplates();
    },

    onColumnFilter(params) {
      var newObj = { filters: {} };
      Object.keys(params.columnFilters).forEach(function (key) {
        if (params.columnFilters[key] !== "")
          newObj.filters[key] = params.columnFilters[key];
      });
      this.updateParams(newObj);
      this.serverParams.page = 1;
      this.getTemplates();
    },
    deleteTaskTemplate(id) {
      Swal({
        title: "Delete",
        text: "Are you sure you want to delete?",
        type: "warning",
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#32a852",
        cancelButtonColor: "#f00",
        confirmButtonText: "Yes",
        cancelButtonText: "No",
      }).then((res) => {
        if (res.value) {
          user
            .delete_task_template(id)
            .then((res) => {
              Swal.fire({
                title: "Success",
                text: res.data.message,
                icon: "success",
                timer: 2500,
              });
              this.getTemplates();
            })
            .catch((err) => {
              Swal.fire({
                title: "Error",
                text: err.response.data.detail,
                icon: "error",
                timer: 2500,
              });
            });
        }
      });
    },
    timeStrToHour(event, action) {
      if (action == "toMin") {
        return moment.duration(event).asMinutes();
      } else {
        const hours = Math.trunc(event / 60);
        const min = event % 60;
        return moment({
          hours: hours,
          minutes: min,
        }).format("HH:mm");
      }
    },
    submitHandler() {
      if (this.reqObj.activities.length == 0) {
        Swal.fire("Error", "Please Add Atleast One Activity", "error");
        return;
      }

      this.reqObj.activities.map(
        (item) =>
          (item.estimated_minutes = this.timeStrToHour(
            item.estimated_minutes,
            "toMin"
          ))
      );
      if (this.modal_title == "Add Template") {
        user
          .create_task_template(this.reqObj)
          .then((res) => {
            Swal.fire({
              title: "Success",
              text: res.data.message,
              icon: "success",
              timer: 2500,
            });
            this.getTemplates();
          })
          .catch((err) => {
            Swal.fire({
              title: "Error",
              text: err.response.data.detail,
              icon: "error",
              timer: 2500,
            });
          });
        $("#templateModal").modal("hide");
      } else {
        user
          .update_task_template(this.template_id, this.reqObj)
          .then((res) => {
            Swal.fire({
              title: "Success",
              text: res.data.message,
              icon: "success",
              timer: 2500,
            });
            this.getTemplates();
          })
          .catch((err) => {
            Swal.fire({
              title: "Error",
              text: err.response.data.detail,
              icon: "error",
              timer: 2500,
            });
          });
        $("#templateModal").modal("hide");
      }
    },
    activityActionHandler(action, index) {
      let activity_obj = {
        activity_desc: null,
        estimated_minutes: 0,
      };
      if (action == "add") {
        this.reqObj.activities.push(activity_obj);
      }
      if (action == "delete") {
        this.reqObj.activities.splice(index, 1);
      }
    },
    getTemplates() {
      this.isLoading = true;
      user.get_task_template(this.serverParams).then((res) => {
        // console.log(res.data.data.data);
        this.template_rows = res.data.data.data;
        this.isLoading = false;
      });
    },
    getTemplateById(id) {
      this.dataLoaded = false;
      user.get_task_template_by_id(id).then((res) => {
        const data = res.data.data;
        this.template_id = data.id;
        this.reqObj.short_desc = data.short_desc;
        this.reqObj.description = data.description;
        this.reqObj.activities = data.activities.map((item) => {
          item.estimated_minutes = this.timeStrToHour(
            item.estimated_minutes,
            "toStr"
          );
          return item;
        });
        this.dataLoaded = true;
      });
    },
    templateModalHandler(action, item) {
      if (action == "add") {
        this.modal_title = "Add Template";
        this.activityActionHandler("add");
        $("#templateModal").modal("show");
      }
      if (action == "update") {
        this.modal_title = "Update Template";
        this.getTemplateById(item.id);
        $("#templateModal").modal("show");
      }
      if (action == "view") {
        this.readonly = true;
        this.modal_title = "View Template";
        this.getTemplateById(item.id);
        $("#templateModal").modal("show");
      }
    },
    hideModalEventHandler() {
      this.readonly = false;
      this.template_id = null;
      this.modal_title = null;
      this.reqObj.short_desc = "";
      this.reqObj.description = "";
      this.reqObj.activities = [];
      this.template_id = null;
    },
  },
  mounted() {
    $("#templateModal").on("hidden.bs.modal", this.hideModalEventHandler);
    this.getTemplates();
  },
  created() {
    user.createSiteUsage(this.siteLogReq);
  },
};
</script>
<style scoped>
.flip-list-move {
  transition: transform 0.5s;
}

.no-move {
  transition: transform 0s;
}

.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}

.list-group {
  min-height: 20px;
}

.list-group-item {
  cursor: move;
  padding: 0;
}

.list-group-item i {
  cursor: pointer;
}
</style>
